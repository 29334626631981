export default {
    SHIFTS: [
        [12, '1st Shift (Charlotte)'],
        [14, '1st Shift Temporary (Charlotte)'],
        [16, '2nd Shift (Charlotte)'],
        [25, '2nd Shift Temporary (Charlotte)'],
        [37, '3rd Shift (Charlotte)'],
        [42, '3rd Shift Temporary (Charlotte)'],
        [23, 'Office Hourly (Charlotte)'],
        [13, 'Salary (Charlotte)'],
        [17, '1st Shift (Dallas)'],
        [19, '1st Shift Temporary (Dallas)'],
        [18, '2nd Shift (Dallas)'],
        [26, '2nd Shift Temporary (Dallas)'],
        [38, '3rd Shift (Dallas)'],
        [43, '3rd Shift Temporary (Dallas)'],
        [20, 'Office Hourly (Dallas)'],
        [22, 'Salary (Dallas)'],
        [1,  '1st Shift (Minneapolis)'],
        [7,  '1st Shift Temporary (Minneapolis)'],
        [2,  '2nd Shift (Minneapolis)'],
        [10, '2nd Shift Temporary (Minneapolis)'],
        [35, '3rd Shift (Minneapolis)'],
        [40, '3rd Shift Temporary (Minneapolis)'],
        [6,  'Office Hourly (Minneapolis)'],
        [3,  'Salary (Minneapolis)'],
        [9,  'Weekend Shift (Minneapolis)'],
        [11, 'Weekend Shift Temporary (Minneapolis)'],
        [27, '1st Shift (Phoenix)'],
        [28, '1st Shift Temporary (Phoenix)'],
        [29, '2nd Shift (Phoenix)'],
        [30, '2nd Shift Temporary (Phoenix)'],
        [39, '3rd Shift (Phoenix)'],
        [44, '3rd Shift Temporary (Phoenix)'],
        [33, 'Office Hourly (Phoenix)'],
        [34, 'Salary (Phoenix)'],
        [31, 'Weekend Shift (Phoenix)'],
        [32, 'Weekend Shift Temporary (Phoenix)'],
        [4,  '1st Shift (Seattle)'],
        [8,  '1st Shift Temporary (Seattle)'],
        [15, '2nd Shift (Seattle)'],
        [24, '2nd Shift Temporary (Seattle)'],
        [36, '3rd Shift (Seattle)'],
        [41, '3rd Shift Temporary (Seattle)'],
        [21, 'Office Hourly (Seattle)'],
        [5,  'Salary (Seattle)'],
    ],
    SHIFT_TYPES: [
        ['1st', '1st Shift'],
        ['2nd', '2nd Shift'],
        ['3rd', '3rd Shift'],
        ['Weekend', 'Weekend'],
        ['Temporary', 'Temporary'],
        ['Salary', 'Salary'],
        ['Office Hourly', 'Office Hourly']
    ]
};