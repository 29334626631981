<template>
    <div>
        <h1>First Clock In Time</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from '@/store/Report.store.js'
    import Options from "./first_clock_in_opt";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    export default {
        name: 'first_clock_in',
        components: {Options, Results, Fields},
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: new Date().toLocaleDateString(), qsField: '[datetimes][clock_time_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[datetimes][clock_time_to]'},
                    employeeId: {val: 0, qsField: '[numbers][u.user_id]'},
                    shifts: {val: 0, qsField: '[numbers][uss.user_staff_shift_id]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][cl.cdi_location_id]'}
                },
                groupByFields: {},
                dataFields: {
                    employee_name: {label: 'Employee', checked: true},
                    user_staff_shift: {label: 'Shift', checked: true},
                    cdi_location: {label: 'Location', checked: true},
                    time_in: {label: 'First Clock In Time', checked: true}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['First Clock In Time']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>