<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date From</label>
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date To</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Employee</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.employeeId.val"/>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shifts</label>
                <select class="form-control" v-model="state.optionFields.shifts.val">
                    <option value="">All</option>
                    <option v-for="[id, location] in cache.shiftCache.SHIFTS" :key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" :key="id" :value="id">{{ location }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";
    import DatePicker from "../utils/DatePicker";
    import shiftCache from "@/cache/shift.cache.js";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: 'Options',
        components: { DatePicker, Typeahead },

        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    shiftCache
                },
            }
        }
    }
</script>